import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { CenteredContent } from './CenteredContent';
import { PageBackground } from "./PageBackground"
import scanner from './images/lines.svg';

type AnalyzingProps = {
  onComplete: () => void
}

export function Analyzing(props: AnalyzingProps) {
  const [analyzed, setAnalyzed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnalyzed(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [setAnalyzed]);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onComplete();
    }, 8000);
    return () => clearTimeout(timer);
  }, [props.onComplete]);

  return <>
    <PageBackground backgroundColor="#69AEBD" textColor="white"/>
    <div className={classNames("centered", "flipped", 'main-content')}>
      {!analyzed ?
        (<>
          <div className='scan-container'>
            <img className={classNames("overlay", "scanner")} src={scanner}/>
          </div>
          <CenteredContent>
            <p className="big-text">
              Analyzing Cracks…
            </p>
          </CenteredContent>
        </>) : 
        (<>
          <CenteredContent>
            <p className="big-text">
              Turn your device around to face you.
            </p>
          </CenteredContent>
        </>)
      }
    </div>
  </>;
}