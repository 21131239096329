import classNames from 'classnames';
import React from 'react';
import { DRAG_CLASS } from "./drag";
import { Point } from "./geometry";
import handle from './images/handle.svg';

const SIZE = '2.5rem';

type TargetViewProps = {
  center: Point
  index: number
}
export  function TargetView(props: TargetViewProps) {
  return (
    <div className={classNames(DRAG_CLASS, 'centered', 'justify-centered')}
      data-index={props.index}
      style={{
        position: "absolute", minWidth: SIZE,
        left: `calc(${100 * props.center.x}% - ${SIZE} / 2)`,
        top: `calc(${100 * props.center.y}% - ${SIZE} / 2)`,
        width: SIZE,
        height: SIZE,
      }}
    >
      <img className={classNames("no-select", "handle")} src={handle}/>
    </div>
    );
  }