import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { addPoint, clampPoint, isValidQuad, orderQuad, Quad, scaleQuad } from './geometry';
import { PageBackground } from './PageBackground';
import { TargetView } from './TargetView';
import { useDrag } from './drag';
import { debounce, flattenDeep } from 'lodash';

function renderCanvas(canvas: HTMLCanvasElement, outline: Quad) {
 // TODO
 const context = canvas.getContext("2d");

 const canvasScaled = scaleQuad(outline, canvas.width, canvas.height);
 const orderedQuad = orderQuad(canvasScaled);

 if(!context) {
     console.error("Unable to get canvas context");
     return;
 }

 const path = new Path2D();

 path.moveTo(orderedQuad.topLeft.x, orderedQuad.topLeft.y);
 path.lineTo(orderedQuad.topRight.x, orderedQuad.topRight.y);
 path.lineTo(orderedQuad.botRight.x, orderedQuad.botRight.y);
 path.lineTo(orderedQuad.botLeft.x, orderedQuad.botLeft.y);
 path.closePath();
 context.fillStyle = '#00E0FF80';
 context.clearRect(0, 0, canvas.width, canvas.height);
 context.fill(path);
}

const debouncedRender = debounce(renderCanvas);

type OutliningProps = {
  image: string,
  onComplete: () => void
  onRetake: () => void
};
export function Outlining(props: OutliningProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const starts: Quad = [
    {x: 0.2, y: 0.2},
    {x: 0.2, y: 0.8},
    {x: 0.8, y: 0.8},
    {x: 0.8, y: 0.2}
  ];

  const targetState = starts.map(p => useState(p));
  const states = targetState.map(([state, _]) => state);
  const setters = targetState.map(([_, setter]) => setter);
  const statesDeps = flattenDeep(states.map(p => [p.x, p.y]));

  useEffect(() => {
    if(canvasRef.current && isValidQuad(states)) {
        const canvasElement = canvasRef.current;
        requestAnimationFrame(() => debouncedRender(canvasElement, states));
    }
  }, [props.image, canvasRef.current, ...statesDeps]);

  const dragState = useDrag(containerRef.current);
  useEffect(() => {
      if(dragState.state === 'tracking') {
          const index = Number.parseInt(dragState.element.dataset["index"] || "-1");
          if(index < 0) {
              return;
          }
          const newLocation = clampPoint(addPoint(dragState.elementStartCenter, dragState.delta), 0, 1);
          setters[index](newLocation);
      }
  }, [dragState, containerRef.current]);


  return <>
    <PageBackground backgroundColor="black" textColor="white"/>
    <div className={classNames('centered', 'main-content')}>
      <p className='instructions'>Drag the rectangle corners to align them with your screen.</p>
      <div className={classNames('centered', 'justify-centered')}>
        <div style={{position: 'relative'}} className="snapshot-container" ref={containerRef}>
          <div>
            <img className={classNames('snapshot', 'no-select')} src={props.image}/>
            <canvas className="overlay-canvas" ref={canvasRef}/>
            {starts.map((_, i) => <TargetView key={i} center={states[i]} index={i}/>)}
          </div>
        </div>
      </div>
      <button className='flat-button'>Continue</button>
    </div>
    <footer>
      <div className='bottom'>
        <button className='link-button'>Redo picture?</button>
      </div>
    </footer>
  </>
}