import React from 'react';

type CenteredContentProps = {children: React.ReactNode}
export function CenteredContent(props: CenteredContentProps) {
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flex: "1"}}>
      <div style={{flex: "1"}}>
        {props.children}
      </div>
    </div>
  );
}