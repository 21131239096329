import classNames from 'classnames';
import React, { useEffect, useReducer } from 'react';

import scanner from './images/lines.svg';

import { CenteredContent } from "./CenteredContent";
import { PageBackground } from "./PageBackground";

type UpdateAction = {type: 'decrement'};

type PreparingProps = { onComplete: () => void }
export function Preparing(props: PreparingProps) {

  const [counter, updateCounter] = useReducer((state: number, _action: UpdateAction) => {
    return state - 1
  }, 5);

  useEffect(() => {
    const timer = setInterval(() => {
      updateCounter({type: 'decrement'});
    }, 1000);
    return () => clearTimeout(timer);
  }, [updateCounter]);

  if(counter === 0) {
    props.onComplete();
  }

  return <>
    <PageBackground backgroundColor="#C7903E" textColor="white"/>
    <div className={classNames("centered", "flipped", 'main-content')}>
      <div className='scan-container'>
        <img className={classNames("overlay", "scanner")} src={scanner}/>
      </div>
      <CenteredContent>
        <p className="big-text">
          Detecting Screen…
        </p>
        <p className="big-text">
          {counter}
        </p>
      </CenteredContent>
    </div>
  </>
}