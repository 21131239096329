import React, { useEffect } from 'react';

import { PageBackground } from "./PageBackground"

type CapturingProps = {
  onComplete: () => void
}

export function Capturing(props: CapturingProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.onComplete();
    }, 1000);
    return () => clearTimeout(timer);
  }, [props.onComplete]);

  return <>
    <PageBackground backgroundColor="black" textColor="white"/>
  </>;
}