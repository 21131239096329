import useMouse, { MousePosition } from "@react-hook/mouse-position";
import { useMemo, useState } from "react";
import { Optional, safeCast } from "./base";
import { DOMRectCenter, isPointInDOMRect, percentInDOMRect, Point } from "./geometry";

export const DRAG_CLASS = "xxx-element-drag";

const QUIET_STATE = {state: 'quiet'} as const;
const EMPTY_DRAG_STATE = {state: 'empty-drag'} as const;

type DragState = {state: 'quiet'} 
    | {state: 'empty-drag'}
    | {state: 'tracking', element: HTMLElement, mouseClientStart: Point, elementStartCenter: Point, delta: Point};

    function handleDrag(element: HTMLElement, state: DragState, mouse: MousePosition): DragState {
      switch(state.state) {
          case 'quiet':
              if(mouse.isDown && mouse.pageX && mouse.pageY && mouse.clientX && mouse.clientY) {
                  if(!isPointInDOMRect({x: mouse.clientX, y: mouse.clientY}, element.getBoundingClientRect())) {
                      return state;
                  }
                  // find lowest draggable child
                  let child = safeCast<HTMLElement>(HTMLElement, document.elementFromPoint(mouse.pageX, mouse.pageY));
                  while(child) {
                      if(child.classList.contains(DRAG_CLASS)) {
                          break;
                      }
                      child = child.parentElement;
                  }
                  if(child) {
                      return {
                          state: 'tracking', element: child,
                          mouseClientStart: {x: mouse.clientX, y: mouse.clientY},
                          elementStartCenter: percentInDOMRect(DOMRectCenter(child.getBoundingClientRect()), element.getBoundingClientRect()),
                          delta: {x: 0, y: 0}
                      };
                  }
                  else {
                      return EMPTY_DRAG_STATE;
                  }
              }
              else {
                  return state;
              }
          case 'empty-drag':
              if(!mouse.isDown) {
                  return QUIET_STATE;
              }
              else {
                  return state;
              }
          case 'tracking':
              if(!mouse.isDown) {
                  return QUIET_STATE;
              }
              else {
                  const deltaX = ((mouse.clientX || state.mouseClientStart.x) - state.mouseClientStart.x) / element.clientWidth;
                  const deltaY = ((mouse.clientY || state.mouseClientStart.y) - state.mouseClientStart.y) / element.clientHeight;
                  return {
                      ...state,
                      delta: {x: deltaX, y: deltaY}
                  };
              }
      }
  }

// Drag state should return the same object if nothing changed
export function useDrag(element: Optional<HTMLElement>): DragState {
  const [dragState, setDragState] = useState<DragState>({state: 'quiet'});
  const mouse = useMouse(safeCast(Document, element?.getRootNode())?.body || null);
  return useMemo(() => {
      if(element) {
          setDragState(handleDrag(element, dragState, mouse));
      }
      return dragState;
  }, [mouse.clientX, mouse.clientY, mouse.isDown]);
}