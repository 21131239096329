import React from "react";

import { CenteredContent } from "./CenteredContent";
import { PageBackground } from "./PageBackground";

export function DesktopRoot() {
  return <div className='page-container'>
    <PageBackground backgroundColor="black" textColor="white"/>
    <div className="centered">
      <CenteredContent>
        <p className="big-text">
        Turn off phone screen and face it toward your computer's camera
        </p>
      </CenteredContent>
      <button className='flat-button'>READY</button>
    </div>
  </div>
}