
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { CenteredContent } from './CenteredContent';
import { PageBackground } from './PageBackground';

type CameraError = {type: 'not-allowed'} | {type: 'not-found'} | {type: 'unknown'};

type PermissionsProps = {
  videoRef: React.RefObject<HTMLVideoElement>,
  onComplete: () => void
};

export function Permissions(props: PermissionsProps) {
  const [cameraError, setCameraError] = useState<CameraError>()

  useEffect(() => {
    async function getVideoPermissions() {
      if(props.videoRef.current) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: 'front' }
          });
          props.videoRef.current.srcObject = stream;
        }
        catch(e) {
          if(e instanceof DOMException) {
            switch(e.name) {
              case 'NotAllowedError': {
                setCameraError({type: 'not-allowed'});
              }
            }
          }
          else {
            setCameraError({type: 'unknown'});
          }
        }
      }
    }
    getVideoPermissions();
  }, [props.videoRef, setCameraError]);

  const message = (() => {
    if(!cameraError) {
      return "Face your device towards a mirror.";
    }
    switch(cameraError.type) {
      case 'not-allowed':
        return <><a href="https://google.com?q=enable%20camera%20permissons">Enable your camera</a> permissions to take a picture of your phone.</>
      case 'not-found':
        return "Camera not found";
      case 'unknown':
        return "Unknown Error???";
    }
  })();

  return <>
    <PageBackground backgroundColor="black" textColor="white"/>
    <div className={classNames("centered", 'main-content')}>
      <CenteredContent>
        <p className="big-text">
          {message}
        </p>
      </CenteredContent>
      {cameraError ? null : <button className='flat-button' onClick={props.onComplete}>Ready</button>}
    </div>
    <footer/>
  </>
}
