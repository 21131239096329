export type Optional<T> = T | undefined | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function safeCast<T>(ctor: { new(...args: any[]): T }, instance: Optional<object>): T | null {
  if (instance instanceof ctor) {
      return instance;
  }
  else {
      return null;
  }
}