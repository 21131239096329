import { clamp, isUndefined, sortBy } from 'lodash';
import * as cv from '@techstark/opencv-js';

export type Point = {x: number, y: number};
export type Size = {width: number, height: number};
export type Quad = [Point, Point, Point, Point];
export type OrderedQuad = {topLeft: Point, topRight: Point, botRight: Point, botLeft: Point};
export type EllipseArc = {center: Point, size: Size, startAngle: number, endAngle: number};

export function scalePoint(p: Point, xScale: number, yScale: number): Point {
  return {x: p.x * xScale, y: p.y * yScale};
}

export function scaleSize(s: Size, xScale: number, yScale: number): Size {
  return {width: s.width * xScale, height: s.height * yScale};
}

export function scaleQuad(quad: Quad, xScale: number, yScale: number): Quad {
  return quad.map(p => scalePoint(p, xScale, yScale));
}

export function offsetQuad(quad: Quad, dx: number, dy: number): Quad {
  return quad.map(p => ({x: p.x + dx, y: p.y + dy}));
}

export function isValidQuad(quad: [Point?, Point?, Point?, Point?]): quad is Quad {
  return !quad.some(isUndefined);
}

export function orderQuad(quad: Quad): OrderedQuad {
  // smallest first
  const yOrdered = sortBy(quad, [v => v.y]);
  const topCandidate1 = yOrdered[0];
  const topCandidate2 = yOrdered[1];
  const [topLeft, topRight] =
    topCandidate1.x < topCandidate2.x
    ? [topCandidate1, topCandidate2]
    : [topCandidate2, topCandidate1];

  const xOrdered = sortBy(yOrdered.slice(2, 4), [v => v.x]);
  const botCandidate1 = xOrdered[0];
  const botCandidate2 = xOrdered[1];
  const [botLeft, botRight] =
  botCandidate1.x < botCandidate2.x
    ? [botCandidate1, botCandidate2]
    : [botCandidate2, botCandidate1];
  
  return {topLeft, topRight, botRight, botLeft};
}

export function canonicalPoints(ordered: OrderedQuad): Quad {
  return [ordered.topLeft, ordered.topRight, ordered.botRight, ordered.botLeft];
}

export function isPointInDOMRect(point: Point, rect: DOMRect): boolean {
    return point.x >= rect.left && point.x <= rect.right && point.y >= rect.top && point.y <= rect.bottom;
}

export function percentInDOMRect(point: Point, rect: DOMRect): Point {
    return {
        x: (point.x - rect.left) / rect.width,
        y: (point.y - rect.top) / rect.height
    }
}

export function DOMRectCenter(rect: DOMRect): Point {
    return {x: rect.left + rect.width / 2, y: rect.top + rect.height / 2};
}

export function addPoint(p1: Point, p2: Point): Point {
    return {x: p1.x + p2.x, y: p1.y + p2.y};
}

export function subtractPoint(p1: Point, p2: Point): Point {
    return {x: p1.x - p2.x, y: p1.y - p2.y};
}

export function clampPoint(point: Point, min: number, max: number): Point {
    return {x: clamp(point.x, min, max), y: clamp(point.y, min, max)};
}

export function applyPerspective(t: cv.Mat, p: Point): Point {
  const get = (y: number, x: number) => t.data64F[y * t.cols + x];
  const x = (get(0, 0) * p.x + get(0, 1) * p.y + get(0, 2)) / (p.x * get(2, 0) + get(2, 1) * p.y + get(2, 2));
  const y = (get(1, 0) * p.x + get(1, 1) * p.y + get(1, 2)) / (p.x * get(2, 0) + get(2, 1) * p.y + get(2, 2));

  return {x, y};
}

export function pathOfPointVector(t: cv.Mat, points: cv.PointVector): Path2D {
  const path = new Path2D();
  for(let i = 0; i < points.size(); i++) {
    const point = applyPerspective(t, points.get(i));
    if(i === 0) {
      path.moveTo(point.x, point.y);
    }
    else {
      path.lineTo(point.x, point.y);
    }
  }
  path.closePath();
  return path;
}

export function pathOfPoints(points: Point[]) {
  const path = new Path2D();
  for(let i = 0; i < points.length; i++) {
    const point = points[i];
    if(i === 0) {
      path.moveTo(point.x, point.y);
    }
    else {
      path.lineTo(point.x, point.y);
    }
  }
  path.closePath();
  return path;

}

export function pathOfOrderedQuad(q: OrderedQuad) {
  return pathOfPoints([q.topLeft, q.topRight, q.botRight, q.botLeft]);
}