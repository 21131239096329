import React, { useCallback, useRef, useState } from 'react';
import { Preparing } from './Preparing';
import { Permissions } from './Permissions';
import { Capturing } from './Capturing';
import { Analyzing } from './Analyzing';
import { Outlining } from './Outlining';


type FlowState = {type: 'permissions'}
  | {type: 'preparing'}
  | {type: 'capturing'}
  | {type: 'analyzing'}
  | {type: 'outlining'}

export function MobileRoot() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [flowState, setFlowState] = useState<FlowState>({type: 'permissions'});
  const [image, setImage] = useState<string>();

  const onPermitted = useCallback(() => {
    setFlowState({type: 'preparing'});
  }, [setFlowState]);

  const onPrepared = useCallback(() => {
    setFlowState({type: 'capturing'});
  }, [setFlowState]);

  const onCaptured = useCallback(() => {
    if(!videoRef.current) {
      console.error('video ref unexpectedly null');
      return;
    }
    // capture
    const canvas = document.createElement('canvas');
    const width = videoRef.current.videoWidth;
    const height = videoRef.current.videoHeight;
    canvas.width = width;
    canvas.height = height;
    const canvasContext = canvas.getContext("2d")!;
    canvasContext.drawImage(videoRef.current, 0, 0, width, height);
    setImage(canvas.toDataURL('image/jpeg'));
    
    setFlowState({type: 'analyzing'});
  }, [setFlowState, setImage]);

  const onAnalyzed = useCallback(() => {
    setFlowState({type: 'outlining'});
  }, [setFlowState]);

  const onOutlined = useCallback(() => {
    // TODO
  }, [setFlowState]);

  const onRetake = useCallback(() => {
    setFlowState({type: 'preparing'});
    setImage(undefined);
  }, [setFlowState, setImage])

  const body = (() => {
    switch(flowState.type) {
      case 'permissions':
        return <Permissions
          videoRef={videoRef} 
          onComplete={onPermitted}
        />
      case 'preparing':
        return <Preparing onComplete={onPrepared}/>
      case 'capturing':
        return <Capturing onComplete={onCaptured}/>
      case 'analyzing':
        return <Analyzing onComplete={onAnalyzed}/>
      case 'outlining':
        return <Outlining image={image!} onComplete={onOutlined} onRetake={onRetake}/>
    }
  })();
  
  return <div className='page-container'>
    {body}
    <video style={{display: 'none'}} ref={videoRef}></video>
  </div>
}