import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DesktopRoot } from './DesktopRoot';
import './index.css';
import { LINKS } from './links';
import { MobileRoot } from './MobileRoot';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path={LINKS.root} element={<MobileRoot/>}/>
      <Route path={LINKS.desktopRoot} element={<DesktopRoot/>}/>
      <Route path={LINKS.mobileRoot} element={<MobileRoot/>}/>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
